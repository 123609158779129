import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { Area, MyHttpApi, OrderMessageGroupUpdateRequest, Printer, PrivilegeItem, Product, ProductCategory, ProductOrderMessageGroup, ProductSubCategory, Station } from 'utils/api';
import { getPrivilege } from 'utils/corporation-util';
//import { clientIdToTypeAndName, getSelectedClient, privilegeItemFromElement } from 'utils/corporation-util';
import { Notify } from 'utils/notify';

interface UIProductOrderMessageGroupUpdateRequest {
	id?: string;
	product?: Product;
	orderMessageGroupId?: string;
}

@autoinject
export class PosOrderMessageEdit {

	private printer: { [key: string]: boolean; } = {};
	private selectedArea: { [key: string]: boolean; } = {};
	private selectedStation: { [key: string]: boolean; } = {};
	private stationList: Station[] = [];
	private stationByAreaId: { [key: string]: Station[]; } = {};
	private printerList: Printer[] = [];
	private areaList: Area[] = [];
	private productCategoryList: ProductCategory[] = [];
	private productSubCategoryListByProductCategoryId: { [key: string]: ProductSubCategory[]; } = {};
	private privilege?: PrivilegeItem;
	private productOrderMessageGroupUpdateRequest: UIProductOrderMessageGroupUpdateRequest = {};
	private productOrderMessageGroupList: ProductOrderMessageGroup[] = [];

	// private orderMessageGroupList: OrderMessageGroupExtended[] = [];
	// private orderMessageGroupMap: { [key: string]: OrderMessageGroupExtended; } = {};
	// private orderMessageGroupRequest: OrderMessageGroupUpdateRequest = {
	// 	name: "",
	// 	orderMessages: [],
	// 	delete: false,
	// };


	constructor(private api: MyHttpApi, private router: Router, private i18n: I18N, private notify: Notify) {
	}

	//
	async activate(params: { id?: string, clientId?: number; }) {
		this.privilege = getPrivilege();

		let [printerList, areaList, productCategoryList, productSubCategoryList, stationList] = await Promise.all([
			this.api.printerList({ id: this.privilege?.id }),
			this.api.areaList({ id: this.privilege?.id }),
			this.api.productCategoryList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
			this.api.productSubCategoryList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
			this.api.stationList({ id: this.privilege?.id }),
		]);

		this.stationByAreaId = {};
		for (let station of this.stationList) {
			if (!(station.areaId in this.stationByAreaId)) {
				this.stationByAreaId[station.areaId] = [];
			}
			this.stationByAreaId[station.areaId].push(station);
		}
		this.stationByAreaId = {};

		this.stationList = stationList.filter(x => !x.deleteTime);

		for (let station of this.stationList) {
			if (!(station.areaId in this.stationByAreaId)) {
				this.stationByAreaId[station.areaId] = [];
			}
			this.stationByAreaId[station.areaId].push(station);
		}
		this.printerList = printerList.filter(x => !x.deleteTime);
		this.areaList = areaList.filter(x => !x.deleteTime);
		this.productCategoryList = productCategoryList.filter(x => !x.deleteTime && !x.supersededById) // || this.orderList.productCategoryId.includes(x.id));
		for (let psc of productSubCategoryList) {
			if (!(psc.productCategoryId in this.productSubCategoryListByProductCategoryId)) {
				this.productSubCategoryListByProductCategoryId[psc.productCategoryId] = [];
			}
			this.productSubCategoryListByProductCategoryId[psc.productCategoryId].push(psc);
		}

	}

	async save(deleted: boolean) {
		this.router.navigateBack();
	}
}
