import { autoinject, computedFrom } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { Client, MyHttpApi, Printer } from 'utils/api';
import { getSelectedClient } from 'utils/corporation-util';

@autoinject
export class PosPrinterList {
	private fieldList: FieldSpec[] = [
		{ key: "name", type: "text" },
		{ key: "printers", header: "common.printer", type: "text", },
		{ key: "copies", type: "number" },
		{ key: "areas", header: "common.area", type: "text" },
		{ key: "productCategories", header: "common.productCategory", type: "text" },
		{ key: "productSubCategories", header: "common.productSubCategory", type: "text" },
		{ key: "deleteTime", header: "common.deleted", type: "boolean" },
	];

	private clientId?: number;

	constructor(private api: MyHttpApi, private router: Router) {
	}

	async activate() {
		let clientList = await this.api.clientList();
		let clients: { [key: number]: Client; } = {};
		for (let c of clientList) {
			clients[c.id] = { ...c, name: c.nickname };
		}
		this.clientId = getSelectedClient();
		this.fieldList = [
			...this.fieldList,
			{ key: "clientId", header: "unit.unit", type: "lookup", lookupData: clients, lookupKey: "name", },
		];

		/*
		let [orderListList] = await Promise.all([
		  this.api.orderListListWithDependencies(),
		]);
		this.orderListList = orderListList;
		*/

		await this.search();
	}

	async search() {
		if (!this.clientId) {
			//this.itemList = [];
			return;
		}
		const itemList = await this.api.printerList({ id: this.clientId });
		//this.itemList = itemList.filter(x => !x.deleteTime);
	}

	rowCall(key: string, row?: Printer) {
		this.router.navigateToRoute(this.editUrl, { id: row?.id, clientId: this.clientId });
	}

	@computedFrom("router.currentInstruction.config.navModel.config.name")
	get editUrl() {
		let routeName = this.router?.currentInstruction?.config?.navModel?.config?.name || "";
		return routeName.replace(/-list$/, "-edit");
	}

	@computedFrom("clientId")
	get searchListener() {
		// eslint-disable-next-line @typescript-eslint/no-floating-promises
		this.search();
		return "";
	}
}
